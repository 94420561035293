import routes from '../fos_js_routes.json';
import Routing from '@vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

let env = window.location.pathname.split('/')[1];

if (env.endsWith('.php')) {
  routes.base_url = '/' + env;
}

routes.host = window.location.host;
routes.scheme = window.location.protocol;
routes.scheme = routes.scheme.substr(0, routes.scheme.length - 1);

Routing.setRoutingData(routes);

export {Routing};
