import '@trackad-css/security/login.css';

import '@external/icheck';

import { Routing } from '@external/router';
import { Translator } from '@external/translator';

$('#_form').submit((event) => {
  const $target = $(event.target);

  if ($target.data('isCaptchaEnabled') === false || $target.data('isCaptchaPassed')) {
    return;
  }

  event.preventDefault();
  grecaptcha.ready(() => {
    grecaptcha.execute($target.data('recaptchaSiteKey'), { action: 'submit'} ).then((responseToken) => {
      $.ajax({
        method: 'POST',
        url: Routing.generate('trackad_verify_recaptcha'),
        dataType: 'json',
        data: JSON.stringify({
          response_token: responseToken,
        }),
        success: ({ success }) => {
          if (success) {
            $target.data('isCaptchaPassed', true).submit();
          } else {
            toastr.error(Translator.trans('pages.login.verifyRecaptchaError'));
          }
        },
      });
    });
  });
});

(function(window, document, $) {
  $('.skin-flat input').iCheck({
    checkboxClass: 'icheckbox_flat-green',
    radioClass: 'iradio_flat-green'
  });
})(window, document, $);
